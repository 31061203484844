<template>
  <div>
    <rents-table
      @refetch-data="fetchRents"
    />
  </div>
</template>

<script>
import {

} from 'bootstrap-vue'
import useRentList from '@/views/rents/useRentList'
import RentsTable from '@/views/rents/rents-list/rentsTable.vue'

export default {
  components: {
    RentsTable,

  },
  data() {
    return {
      data: {},
    }
  },
  setup() {
    const {
      groupOptions,
      fetchRents,
      getRents,
    } = useRentList()
    fetchRents()

    return {
      groupOptions,
      fetchRents,
      getRents,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
